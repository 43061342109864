import React, { useState, useEffect, useReducer, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";
import { IconContext } from 'react-icons' //IconContextをインポート
import { HiUserCircle } from "react-icons/hi";
import TopBtn from './TopBtn';
import Main from './Main';
import Setting from './Setting';
function Menu(props) {
    const { btnFlg, setBtnFlg } = useContext(ApiContext);
    return (
        <div className='contents'>
            <div className="navi">
                <div className="navi_box">
                    <h1>三原AIカメラ</h1>
                </div>
                <div className='navi_right'>
                    <IconContext.Provider value={{ color: '#f06d45', size: '30px' }}>
                        <HiUserCircle className='shdowLock' />
                    </IconContext.Provider>
                    {/*<p id='user_name'>{props.user}</p>*/}
                </div>






            </div>
            {btnFlg && <TopBtn kengen={props.kengen} />}
            {!btnFlg && <Setting />}
        </div>
    )
}

export default Menu