import React, { useState, useEffect } from 'react';
import { Scatter } from 'react-chartjs-2';
import 'chart.js/auto';
import faker from 'faker';

const ScatterChart = () => {
    const [data, setData] = useState({
        datasets: []
    });

    useEffect(() => {
        const generateData = (gender, color) => Array.from({ length: 50 }, () => ({
            x: faker.datatype.number({ min: 0, max: 120 }), // 0-24時間
            y: faker.datatype.number({ min: 0, max: 30 }), // 0-100人
            gender: gender
        })).map(point => ({ ...point, borderColor: color, backgroundColor: color }));

        setData({
            datasets: [
                {
                    label: '男性',
                    data: generateData('male', 'rgba(54, 162, 235, 1)'),
                },
                {
                    label: '女性',
                    data: generateData('female', 'rgba(255, 99, 132, 1)'),
                }
            ]
        });
    }, []);

    const options = {
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                title: {
                    display: true,
                    text: '滞在時間 (時間)'
                }
            },
            y: {
                title: {
                    display: true,
                    text: '人数'
                }
            }
        }
    };

    return <Scatter data={data} options={options} />;
};

export default ScatterChart;
