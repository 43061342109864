import React, { useState, useEffect, useCallback, useContext } from 'react'
import { IconContext } from 'react-icons' //IconContextをインポート
import { BiCloudDownload } from "react-icons/bi";
import axios from "axios";

const Profile = () => {
    const save = async () => {
        var token = '15f1867c30067b4e5913f72322a0823afde4a48d'
        const uploadData = new FormData();
        uploadData.append("author", 5);
        var body = document.getElementById('profile').value
        uploadData.append("body", body);

        const res = await axios.patch('https://tictac-clip.com/api/town/1/', uploadData, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });


        alert('保存しました')
    }
    const loadTown = async () => {

        const res = await axios.get('https://tictac-clip.com/api/town/1/', {

        });

        console.log(res.data['body']);

        var textArea = document.getElementById('profile').value = res.data['body'];
        //textArea.value = res.data['body'];



    }
    useEffect(() => {
        loadTown();

    }, []);
    return (
        <div className='profileBlock'>
            <p className='eventTitle'>街の特徴/プロフィール</p>
            <textarea className='profile_text' id="profile" placeholder=''>

            </textarea>
            <button className='btn' id='setting4' onClick={save}>
                <IconContext.Provider value={{ color: '#FFF', size: '40px', className: 'shdow' }}>
                    <BiCloudDownload />
                </IconContext.Provider>
            </button>

        </div>
    )
}

export default Profile