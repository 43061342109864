import React, { useState, useEffect, useReducer, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";

import { IconContext } from 'react-icons' //IconContextをインポート


import { HiUserCircle } from "react-icons/hi";

import { BiCloudDownload } from "react-icons/bi";

import { Bar_graph } from "../graph/Bar_graph";
import { En_graph } from "../graph/En_graph";
import { En_graph2 } from "../graph/En_graph2";
import { ChartEn2 } from "../graph/En_graph2";
import { Line_graph2 } from "../graph/Line_graph2";
import { Line_graph } from "../graph/Line_graph";
import loading_img from '../img/loading.gif';
import dumy from '../img/dumy.png';
import dumy2 from '../img/noimg.png';
import { IoReloadSharp } from "react-icons/io5";
import axios from "axios";

const Camera = (props) => {
    const { set_csv, getUser, userID, setUserID, spotList, setSpostList, faceList, setFaceList, allList, selectSpot, setSelectSpot, loading, setLoading, projectName, loginName, setLoginName } = useContext(ApiContext);

    const [targetDay, setTargetDay] = useState('');
    const [targetTime, setTargetTime] = useState('');
    const [targetDay2, setTargetDay2] = useState('');
    const [targetTime2, setTargetTime2] = useState('');




    const labelsA = ['男性(%)', '女性(%)']
    const dataA = [12, 19]
    const colorA = ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)']


    const labelsB = ['0〜9歳', '10代', '20代', '30代', '40代', '50代', '60代', '70歳〜']

    const colorB = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(150, 255, 150, 0.2)',
        'rgba(0, 250, 250, 0.2)',
        'rgba(10, 10, 10, 0.2)'
    ]


    const now_csv = (e) => {
        const cal_day1 = document.getElementById('cal_day1').value;
        const cal_day2 = document.getElementById('cal_day2').value;

        const cal_day1_f = cal_day1.replace(/-/g, '/')
        const cal_day2_f = cal_day2.replace(/-/g, '/')
        const from = '&from_day=' + cal_day1_f + ' 00:00'
        const to = '&to_day=' + cal_day2_f + ' 23:59'
        const str = from + to;
        set_csv(str)

    }


    const now_data = () => {

        let cal_day1 = document.getElementById('cal_day1').value;
        let cal_day2 = document.getElementById('cal_day2').value;
        const cal_day1_f = cal_day1.replace(/-/g, '/')
        const cal_day2_f = cal_day2.replace(/-/g, '/')


        const from = '&from_day=' + cal_day1_f + ' 00:00'
        const to = '&to_day=' + cal_day2_f + ' 23:59'
        const str = from + to;

        if (str != '&from_day=&to_day=' && userID != 0) {
            console.log(loading)

            allList(str)
        }

        //get_data(str)

        /*
          let cal_day1 = document.getElementById('cal_day1').value;
          let cal_day2 = document.getElementById('cal_day2').value;
          const cal_time1 = document.getElementById('cal_time1').value;
          const cal_time2 = document.getElementById('cal_time2').value;
          */
    }

    const changeSpot = (event) => {
        console.log('click!')
        setSelectSpot(event.target.value);

        //selectStb(event.target.value)
        //changeTimeLine(event.target.value);
    }



    useEffect(() => {
        now_data();

    }, [userID]);



    useEffect(() => {

        getUser();
        getNowTime();
        getImg("20");

    }, []);

    useEffect(() => {
        now_data();

    }, [userID]);

    const getNowTime = () => {

        const now = new Date();
        now.setDate(now.getDate());
        let y = now.getFullYear();
        let m = ('00' + (now.getMonth() + 1)).slice(-2);
        let d = ('00' + now.getDate()).slice(-2);
        setTargetDay(y + '-' + m + '-' + d);



        now.setDate(now.getDate());
        y = now.getFullYear();
        m = ('00' + (now.getMonth() + 1)).slice(-2);
        d = ('00' + now.getDate()).slice(-2);
        setTargetDay2(y + '-' + m + '-' + d);


    }

    const spotChg = (event) => {

        getImg(event.target.value);
    }
    const [ageStr, setAgeStr] = useState("〜10歳　" + 0 + "人(" + (0) + "%)　10〜19歳　" + 0 + "人(" + (0) + "%)　20〜29歳　" + 0 + "人(" + (0) + "%)　30〜39歳　" + 0 + "人(" + (0) + "%)　40〜49歳　" + 0 + "人(" + (0) + "%)　50〜59歳　" + 0 + "人(" + (0) + "%)　60〜69歳　" + 0 + "人(" + (0) + "%)　70歳〜　" + 0 + "人(" + (0) + "%)");
    const [genderStr, setGenderStr] = useState("通行人数:" + 0 + "人" + "　男性:" + 0 + "人(" + 0 + "%)　女性:" + 0 + "人(" + 0 + "%)");

    const total = ""
    const countM_temp = ""
    const countF_temp = ""
    //setDatas([countM_temp / total * 100, countF_temp / total * 100])
    //setGenderStr("通行人数:" + total + "人" + "　男性:" + countM_temp + "人(" + (countM_temp / total * 100).toFixed(2) + "%)　女性:" + countF_temp + "人(" + (countF_temp / total * 100).toFixed(2) + "%)")
    //setAgeStr("〜10歳　" + count_temp[0] + "人(" + (count_temp[0] / total * 100).toFixed(2) + "%)　10〜19歳　" + count_temp[1] + "人(" + (count_temp[1] / total * 100).toFixed(2) + "%)　20〜29歳　" + count_temp[2] + "人(" + (count_temp[2] / total * 100).toFixed(2) + "%)　30〜39歳　" + count_temp[3] + "人(" + (count_temp[3] / total * 100).toFixed(2) + "%)　40〜49歳　" + count_temp[4] + "人(" + (count_temp[4] / total * 100).toFixed(2) + "%)　50〜59歳　" + count_temp[5] + "人(" + (count_temp[5] / total * 100).toFixed(2) + "%)　60〜69歳　" + count_temp[6] + "人(" + (count_temp[6] / total * 100).toFixed(2) + "%)　70歳〜　" + count_temp[7] + "人(" + (count_temp[7] / total * 100).toFixed(2) + "%)")
    const getImg = (num) => {
        setSelectSpot(num);

        const url = "https://tictac-clip.com/api/get_img/?folder=" + num
        axios.get(url)
            .then(res => {
                //console.log(res.data)
                let img = res.data

                img = "https://tictac-clip.com" + img.replace('/home/hagumi', '')
                console.log(img)
                //img = "https://tictac-clip.com/media/api/file/2/20240329045549.jpg"
                setPreImg(img)

            })
            .catch(err => {
                const img = "https://tictac-clip.com/media/api/file/noimg.png"
                setPreImg(img)
            })


    }
    const reload = () => {
        getImg(selectSpot);
    }
    const [preImg, setPreImg] = useState('');
    return (
        <div>
            <div className='Calendar_block'>
                <div className='cal_form'>
                    <input id="cal_day1" type="date" name="example1" defaultValue={targetDay}></input>
                    <input id="cal_time1" type="time" name="example1" defaultValue={targetTime}></input>
                    <p>〜</p>
                    <input id="cal_day2" type="date" name="example1" defaultValue={targetDay2}></input>
                    <input id="cal_time2" type="time" name="example1" defaultValue={targetTime2}></input>
                    <button className='btn' id='setting' onClick={now_data}>設定</button>
                    <button className='btn' id='setting2' onClick={now_csv}>
                        <IconContext.Provider value={{ color: '#FFF', size: '40px', className: 'shdow' }}>
                            <BiCloudDownload />
                        </IconContext.Provider>
                    </button>
                </div>
                <div className='select_box'>
                    <form id='login_set'>
                        <select className='timeline_name2' name="timeline_name" onChange={spotChg}>

                            <option value="20">イベントカメラA</option>
                            <option value="21">イベントカメラB</option>






                        </select>
                    </form>
                </div>

            </div>

            <div className='dumy_box2'>
                <img src={preImg} className="dumy2" alt="" />
                <button className='btn' id='setting2' onClick={reload}>
                    <IconContext.Provider value={{ color: '#FFF', size: '40px', className: 'shdow' }}>
                        <IoReloadSharp />
                    </IconContext.Provider>
                </button>

            </div>
            <div className="bar">
                <Bar_graph className="br_g" title={"時間帯別 (人/時間)"} />
            </div>

            <div className="totalBox">
                <div className='total'>
                    <p>{genderStr}</p>

                </div>
                <div className='total2'>
                    <p>{ageStr}</p>



                </div>
            </div>


            <div className='row'>
                <div className="en"><En_graph title={"男女比率"} labels={labelsA} data={dataA} color={colorA} /></div>
                <div className="en"><En_graph2 title={"年齢比率"} labels={labelsB} color={colorB} /></div>
            </div>


            {loading && <div className='loading'><img src={loading_img} alt="" /></div>}
        </div >






    )
}

export default Camera