import React, { useState, useEffect, useReducer, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";
import { Line_graph2 } from "../graph/Line_graph2";
import { Line_graph } from "../graph/Line_graph";
import { En_graph } from "../graph/En_graph";
import { En_graph2 } from "../graph/En_graph2";
import ScatterChart from '../graph/ScatterChart'; // 散布図コンポーネントへのパスを調整
import BubbleChart from '../graph/BubbleChart'; // 散布図コンポーネントへのパスを調整
import AgeScatterChart from '../graph/AgeScatterChart'; // 散布図コンポーネントへのパスを調整
import StackedBarChart from '../graph/StackedBarChart'; // 散布図コンポーネントへのパスを調整
import LineChart from '../graph/LineChart'; // 散布図コンポーネントへのパスを調整
import axios from "axios";


const Hiroba = () => {


    const [koudou, setKoudou] = useState('分析中...');
    const [riyou, setRiyou] = useState('分析中...');
    const [riyou_s, setRiyou_s] = useState('分析中...');
    const [kankei, setKankei] = useState('分析中...');

    const labelsA = ['男性(%)', '女性(%)']
    const dataA = [12, 19]
    const colorA = ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)']


    const labelsB = ['0〜9歳', '10代', '20代', '30代', '40代', '50代', '60代', '70歳〜']

    const colorB = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(150, 255, 150, 0.2)',
        'rgba(0, 250, 250, 0.2)',
        'rgba(10, 10, 10, 0.2)'
    ]

    //allList
    //
    const { set_csv, getUser, userID, setUserID, spotList, setSpostList, faceList, setFaceList, allList, selectSpot, setSelectSpot, loading, setLoading, projectName, loginName, setLoginName } = useContext(ApiContext);

    useEffect(() => {
        allList("&from_day= 00:00&to_day= 23:59");
        koudou_b();
        riyou_b();
        riyou_s_b();
        kankei_b();

    }, [])

    const kankei_b = () => {
        setKankei('解析中...')
        let total = Math.floor(Math.random() * 2500) + 2500;
        let men = Math.floor(Math.random() * 2500) + 2500;
        let women = Math.floor(Math.random() * 2500) + 2500;
        let n10 = Math.floor(Math.random() * 500) + 500;
        let n20 = Math.floor(Math.random() * 500) + 500;
        let n30 = Math.floor(Math.random() * 500) + 500;
        let n40 = Math.floor(Math.random() * 500) + 500;
        let n50 = Math.floor(Math.random() * 500) + 500;
        let n60 = Math.floor(Math.random() * 500) + 500;
        let n70 = Math.floor(Math.random() * 500) + 500;
        let kaisekiStr = '通行量は' + total + '人,男性' + men + '、女性' + women + '人でした。年齢別に見ると、10代が' + n10 + '人、20代が' + n20 + '人、30代が' + n30 + '人、40代が' + n40 + '人、50代が' + n50 + '人、60代が' + n60 + '人、70代が' + n70 + '人でした。'
        kaisekiStr += "これれの通行量を踏まえ,広場づくりにおける「周辺との関係」について50文字程度程度で回答してください。"
        //setTopic(kaisekiStr)
        getGPT_sk(kaisekiStr);


    }


    const riyou_s_b = () => {
        setRiyou('解析中...')
        let total = Math.floor(Math.random() * 2500) + 2500;
        let men = Math.floor(Math.random() * 2500) + 2500;
        let women = Math.floor(Math.random() * 2500) + 2500;
        let n10 = Math.floor(Math.random() * 500) + 500;
        let n20 = Math.floor(Math.random() * 500) + 500;
        let n30 = Math.floor(Math.random() * 500) + 500;
        let n40 = Math.floor(Math.random() * 500) + 500;
        let n50 = Math.floor(Math.random() * 500) + 500;
        let n60 = Math.floor(Math.random() * 500) + 500;
        let n70 = Math.floor(Math.random() * 500) + 500;
        let kaisekiStr = '通行量は' + total + '人,男性' + men + '、女性' + women + '人でした。年齢別に見ると、10代が' + n10 + '人、20代が' + n20 + '人、30代が' + n30 + '人、40代が' + n40 + '人、50代が' + n50 + '人、60代が' + n60 + '人、70代が' + n70 + '人でした。'
        kaisekiStr += "これれの通行量を踏まえ,広場づくりにおける「利用者数」について50文字程度程度で回答してください。"
        //setTopic(kaisekiStr)
        getGPT_r_s(kaisekiStr);


    }




    const riyou_b = () => {
        setRiyou('解析中...')
        let total = Math.floor(Math.random() * 2500) + 2500;
        let men = Math.floor(Math.random() * 2500) + 2500;
        let women = Math.floor(Math.random() * 2500) + 2500;
        let n10 = Math.floor(Math.random() * 500) + 500;
        let n20 = Math.floor(Math.random() * 500) + 500;
        let n30 = Math.floor(Math.random() * 500) + 500;
        let n40 = Math.floor(Math.random() * 500) + 500;
        let n50 = Math.floor(Math.random() * 500) + 500;
        let n60 = Math.floor(Math.random() * 500) + 500;
        let n70 = Math.floor(Math.random() * 500) + 500;
        let kaisekiStr = '通行量は' + total + '人,男性' + men + '、女性' + women + '人でした。年齢別に見ると、10代が' + n10 + '人、20代が' + n20 + '人、30代が' + n30 + '人、40代が' + n40 + '人、50代が' + n50 + '人、60代が' + n60 + '人、70代が' + n70 + '人でした。'
        kaisekiStr += "これれの通行量を踏まえ,広場づくりにおける「利用者の多様性」について50文字程度程度で回答してください。"
        //setTopic(kaisekiStr)
        getGPT_r(kaisekiStr);


    }

    const koudou_b = () => {
        setKoudou('解析中...')
        let total = Math.floor(Math.random() * 2500) + 2500;
        let men = Math.floor(Math.random() * 2500) + 2500;
        let women = Math.floor(Math.random() * 2500) + 2500;
        let n10 = Math.floor(Math.random() * 500) + 500;
        let n20 = Math.floor(Math.random() * 500) + 500;
        let n30 = Math.floor(Math.random() * 500) + 500;
        let n40 = Math.floor(Math.random() * 500) + 500;
        let n50 = Math.floor(Math.random() * 500) + 500;
        let n60 = Math.floor(Math.random() * 500) + 500;
        let n70 = Math.floor(Math.random() * 500) + 500;
        let kaisekiStr = '通行量は' + total + '人,男性' + men + '、女性' + women + '人でした。年齢別に見ると、10代が' + n10 + '人、20代が' + n20 + '人、30代が' + n30 + '人、40代が' + n40 + '人、50代が' + n50 + '人、60代が' + n60 + '人、70代が' + n70 + '人でした。'
        kaisekiStr += "これれの通行量を踏まえ,広場づくりにおける「行動の多様性」について50文字程度程度で回答してください。"
        //setTopic(kaisekiStr)
        getGPT_k(kaisekiStr);


    }

    const getGPT_sk = async (str) => {
        const uploadData = new FormData();
        uploadData.append("topic", str);
        const res = await axios.post(
            `https://hagumi-ai-gpt.net/gpt/api/v1/mihara/`, uploadData,
            {

            }
        );
        setKankei(res.data);
    }

    const getGPT_k = async (str) => {
        const uploadData = new FormData();
        uploadData.append("topic", str);
        const res = await axios.post(
            `https://hagumi-ai-gpt.net/gpt/api/v1/mihara/`, uploadData,
            {

            }
        );
        setKoudou(res.data);
    }


    const getGPT_r = async (str) => {
        const uploadData = new FormData();
        uploadData.append("topic", str);
        const res = await axios.post(
            `https://hagumi-ai-gpt.net/gpt/api/v1/mihara/`, uploadData,
            {

            }
        );
        setRiyou(res.data);
    }

    const getGPT_r_s = async (str) => {
        const uploadData = new FormData();
        uploadData.append("topic", str);
        const res = await axios.post(
            `https://hagumi-ai-gpt.net/gpt/api/v1/mihara/`, uploadData,
            {

            }
        );
        setRiyou_s(res.data);
    }




    return (
        <div>
            <p className='reportTitle'>行動の多様性</p>
            <div className="bar"><ScatterChart /></div>
            <div className='row' id='row'>
                <h1>行動の多様性について</h1>
                <div className='report' >
                    <p>
                        {koudou}
                    </p>
                </div>
            </div >
            <p className='reportTitle'>利用者の多様性</p>
            <div className="bar"><BubbleChart /></div>
            <div className='row' id='row'>
                <h1>利用者の多様性について</h1>
                <div className='report' >
                    <p>
                        {riyou}
                    </p>
                </div>
            </div >

            <p className='reportTitle'>利用者数 に関して</p>
            <div className="bar"><StackedBarChart /></div>
            <div className='row' id='row'>
                <h1>利用者数について</h1>
                <div className='report' >
                    <p>
                        {riyou_s}
                    </p>
                </div>
            </div >

            <p className='reportTitle'>周辺との関係</p>
            <div className="bar"><LineChart /></div>
            <div className='row' id='row'>
                <h1>周辺との関係について</h1>
                <div className='report' >
                    <p>
                        {kankei}
                    </p>
                </div>
            </div >


        </div>
    )
}

export default Hiroba