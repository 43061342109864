import React, { useState, useEffect, useCallback, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";

import { IconContext } from 'react-icons' //IconContextをインポート
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { HiUserCircle } from "react-icons/hi";

import { BiCloudDownload } from "react-icons/bi";

import { Bar_graph } from "../graph/Bar_graph";
import { En_graph } from "../graph/En_graph";
import { En_graph2 } from "../graph/En_graph2";
import { ChartEn2 } from "../graph/En_graph2";
import { Line_graph2 } from "../graph/Line_graph2";
import { Line_graph } from "../graph/Line_graph";
import loading_img from '../img/loading.gif';
import dumy from '../img/dumy.png';
import axios from "axios";
import { ge } from 'faker/lib/locales';

const Main = (props) => {
  const { set_csv, getUser, userID, setUserID, spotList, setSpostList, faceList, setFaceList, allList, selectSpot, setSelectSpot, loading, setLoading, projectName, ageStr, genderStr, get_data, eventStart, setEventStart, eventEnd, setEventEnd, adress, setAdress } = useContext(ApiContext);

  const [targetDay, setTargetDay] = useState('');
  const [targetTime, setTargetTime] = useState('00:00');
  const [targetDay2, setTargetDay2] = useState('');
  const [targetTime2, setTargetTime2] = useState('23:59');
  const [topics, setTopics] = useState('');
  const [topic, setTopic] = useState("2024年1月31日の通行量は3587人,男性1587人、女性,2000人でした。年齢別に見ると、10代が1000人、20代が1500人、30代が1000人、40代が500人、50代が400人、60代が100人、70代が50人でした。");

  const [latA, setLatA] = useState(0.0); // 初期値を適当な値に設定
  const [lngA, setLngA] = useState(0.0); // 初期値を適当な値に設定

  const labelsA = ['男性(%)', '女性(%)']
  const dataA = [12, 19]
  const colorA = ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)']


  const labelsB = ['0〜9歳', '10代', '20代', '30代', '40代', '50代', '60代', '70歳〜']

  const colorB = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(150, 255, 150, 0.2)',
    'rgba(0, 250, 250, 0.2)',
    'rgba(10, 10, 10, 0.2)'
  ]


  const now_csv = (e) => {
    const cal_day1 = document.getElementById('cal_day1').value;
    const cal_day2 = document.getElementById('cal_day2').value;
    const cal_time1 = document.getElementById('cal_time1').value;
    const cal_time2 = document.getElementById('cal_time2').value;
    const cal_day1_f = cal_day1.replace(/-/g, '/')
    const cal_day2_f = cal_day2.replace(/-/g, '/')
    const from = '?from_day=' + cal_day1_f + ' ' + cal_time1
    const to = '&to_day=' + cal_day2_f + ' ' + cal_time2
    const str = from + to;
    set_csv(str)

  }

  const now_data = () => {

    let cal_day1 = document.getElementById('cal_day1').value;
    let cal_day2 = document.getElementById('cal_day2').value;
    const cal_time1 = document.getElementById('cal_time1').value;
    const cal_time2 = document.getElementById('cal_time2').value;


    let manthA = parseInt(cal_day1.split('-')[1])
    let dayA = parseInt(cal_day1.split('-')[2])

    let manthB = parseInt(cal_day2.split('-')[1])
    let dayB = parseInt(cal_day2.split('-')[2])

    /*
    if(manthB == 11 && dayB <19){
      manthB = 11;
      dayB = 19+(10%dayB);
      cal_day2 = '2022-'+manthB +'-' + dayB;
    }
    */





    const cal_day1_f = cal_day1.replace(/-/g, '/')
    const cal_day2_f = cal_day2.replace(/-/g, '/')


    const from = '?from_day=' + cal_day1_f + ' ' + cal_time1
    const to = '&to_day=' + cal_day2_f + ' ' + cal_time2
    const str = from + to;

    console.log("str:" + str)
    get_data(str)


    setTopics('解析中...')

    let total = Math.floor(Math.random() * 2500) + 2500;
    let men = Math.floor(Math.random() * 2500) + 2500;
    let women = Math.floor(Math.random() * 2500) + 2500;
    let n10 = Math.floor(Math.random() * 500) + 500;
    let n20 = Math.floor(Math.random() * 500) + 500;
    let n30 = Math.floor(Math.random() * 500) + 500;
    let n40 = Math.floor(Math.random() * 500) + 500;
    let n50 = Math.floor(Math.random() * 500) + 500;
    let n60 = Math.floor(Math.random() * 500) + 500;
    let n70 = Math.floor(Math.random() * 500) + 500;
    let kaisekiStr = cal_day1 + 'から' + cal_day2 + 'の通行量は' + total + '人,男性' + men + '、女性' + women + '人でした。年齢別に見ると、10代が' + n10 + '人、20代が' + n20 + '人、30代が' + n30 + '人、40代が' + n40 + '人、50代が' + n50 + '人、60代が' + n60 + '人、70代が' + n70 + '人でした。'
    kaisekiStr += inputText
    setTopic(kaisekiStr)
    getGPT(kaisekiStr);
    //get_data(str)

    /*
      let cal_day1 = document.getElementById('cal_day1').value;
      let cal_day2 = document.getElementById('cal_day2').value;
      const cal_time1 = document.getElementById('cal_time1').value;
      const cal_time2 = document.getElementById('cal_time2').value;
      */


  }

  const changeSpot = (event) => {

    setSelectSpot(event.target.value);

    //selectStb(event.target.value)
    //changeTimeLine(event.target.value);
  }



  useEffect(() => {
    now_data();

  }, [userID]);



  useEffect(() => {
    getUser();
    getNowTime();

  }, []);

  useEffect(() => {
    now_data();

  }, [userID]);

  const getNowTime = () => {
    if (eventStart == '') {

      const now = new Date();
      //昨日
      now.setDate(now.getDate() - 1);
      //now.setDate(now.getDate());


      let y = now.getFullYear();
      let m = ('00' + (now.getMonth() + 1)).slice(-2);
      let d = ('00' + now.getDate()).slice(-2);
      setTargetDay(y + '-' + m + '-' + d);



      now.setDate(now.getDate());
      y = now.getFullYear();
      m = ('00' + (now.getMonth() + 1)).slice(-2);
      d = ('00' + now.getDate()).slice(-2);
      setTargetDay2(y + '-' + m + '-' + d);
    } else {

      setTargetDay(eventStart);
      setTargetDay2(eventEnd);

      if (adress != '') {
        getLatLng(adress);
      }
    }

    now_data();
  }


  const containerStyle = {
    width: '900px',
    height: '600px'
  };

  const center = {

    lat: 34.3975764656357,
    lng: 133.08058345433176
  };
  const mapStyles = [
    {
      featureType: "all",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "transit.station",
      elementType: "labels",
      stylers: [{ visibility: "on" }]
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [{ visibility: "on" }]
    }
  ];
  const options = {
    scrollwheel: false, // スクロールによるズーム変更を禁止
    gestureHandling: 'none', // ドラッグ操作による地図の移動を禁止
    styles: mapStyles,
    streetViewControl: false, // ストリートビューのボタンを非表示
    zoomControl: false,       // ズームコントロールを非表示
    mapTypeControl: false,    // マップタイプコントロールを非表示    
    // その他のUI要素も同様に制御可能
  };


  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    const heatmapData = [
      //new window.google.maps.LatLng(34.39971950300504, 133.08052924535798),
      //new window.google.maps.LatLng(34.40144100276853, 133.08086138811151),
      //new window.google.maps.LatLng(34.39987833262487, 133.08347000757394),
      // new window.google.maps.LatLng(34.40175193991538, 133.0784139806027),

      /*
      { location: new window.google.maps.LatLng(34.39971950300504, 133.08052924535798), weight: 0.5 },

      { location: new window.google.maps.LatLng(34.40144100276853, 133.08086138811151), weight: 1 },
      { location: new window.google.maps.LatLng(34.40144100276853, 133.08086138811151 + 0.0002), weight: 1 },
      { location: new window.google.maps.LatLng(34.40144100276853, 133.08086138811151 + 0.0004), weight: 0.5 },
      {
        location: new window.google.maps.LatLng(34.40144100276853 + 0.0002, 133.08086138811151 + 0.0006), weight: 0.25
      },

      { location: new window.google.maps.LatLng(34.39987833262487, 133.08347000757394), weight: 0.75 },
      { location: new window.google.maps.LatLng(34.40175193991538, 133.0784139806027), weight: 0.25 },

      // その他のデータポイント
      */
    ];

    const heatmap = new window.google.maps.visualization.HeatmapLayer({
      data: heatmapData,
      map: map,
      options: {
        radius: 30,  // ヒートマップの点の半径
        opacity: 0.6 // ヒートマップの不透明度
      }
    });
    setSelectSpot("0");
    setMap(map);

    now_data();
  }, []);

  const getGPT = async (str) => {

    const uploadData = new FormData();
    uploadData.append("topic", str);
    const res = await axios.post(
      `https://hagumi-ai-gpt.net/gpt/api/v1/mihara/`, uploadData,
      {

      }
    );
    ///alert(res.data)
    //console.log(res.data)
    setTopics(res.data);
  }


  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const paragraphStyle = {
    whiteSpace: 'pre-wrap'
  };



  const [inputText, setInputText] = useState('');
  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const spotChg = (event) => {
    setSelectSpot(event.target.value);
  }


  function getLatLng(address) {
    // API キーを設定
    const apiKey = "AIzaSyDyn-GCfgexZ61pUQ9-64TPWT5dkugImgs";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

    // Fetch APIを使用してGeocoding APIにリクエストを送信
    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'OK') {
          // 緯度と経度を取得
          const lat = data.results[0].geometry.location.lat;
          const lng = data.results[0].geometry.location.lng;
          console.log('Latitude:', lat, 'Longitude:', lng);
          setLatA(lat);
          setLngA(lng);
        } else {
          console.log('Geocoding failed: ' + data.status);
        }
      })
      .catch(error => console.error('Error:', error));
  }


  const customIcon = {
    url: 'https://ha-app.com/event.png', // アイコンのURL

  };




  const setPoint = () => {

    return (
      <>
        {/* 帝人通り */}
        {(selectSpot === "0" || selectSpot === "3") && (

          <Marker
            position={{ lat: 34.39971950300504, lng: 133.08052924535798 }}
          />
        )}


        {/* 駅裏 */}
        {(selectSpot === "0" || selectSpot === "2") && (
          <Marker
            position={{ lat: 34.40144100276853, lng: 133.08086138811151 }}
          />
        )}

        {/* タコロード */}
        {(selectSpot === "0" || selectSpot === "5") && (
          <Marker
            position={{ lat: 34.39987833262487, lng: 133.08347000757394 }}
          />
        )}

        {/* 山脇亭 */}
        {(selectSpot === "0" || selectSpot === "1") && (
          <Marker
            position={{ lat: 34.40175193991538, lng: 133.0784139806027 }}
          />
        )}

        {/* 広場 */}
        {(selectSpot === "0" || selectSpot === "4") && (
          <Marker
            position={{ lat: 34.39954763166319, lng: 133.08240735635943 }}
          />
        )}

        {/* 港ビル */}
        {(selectSpot === "0" || selectSpot === "6") && (
          <Marker
            position={{ lat: 34.39833482282054, lng: 133.0821176778021 }}
          />
        )}

        {/* 港 */}
        {(selectSpot === "0" || selectSpot === "7") && (
          <Marker
            position={{ lat: 34.39687622060135, lng: 133.0798852142778 }}
          />
        )}

        {/*フジグラン前道路*/}
        {(selectSpot === "0" || selectSpot === "16") && (
          <Marker
            position={{ lat: 34.39252985329796, lng: 133.07786 }}
          />
        )}



        {/* リージョン前道路 */}
        {(selectSpot === "0" || selectSpot === "15") && (
          <Marker
            position={{ lat: 34.39603714707396, lng: 133.07897254232913 }}
          />
        )}


        {/* 勝村建材 */}
        {(selectSpot === "0" || selectSpot === "10") && (
          <Marker
            position={{ lat: 34.39931664680283, lng: 133.07771639602018 }}
          />
        )}


        {/* 花本時計 */}
        {(selectSpot === "0" || selectSpot === "11") && (
          <Marker
            position={{ lat: 34.39910829479862, lng: 133.08026327116454 }}
          />
        )}

        {/* エスポ交差点 */}
        {(selectSpot === "0" || selectSpot === "14") && (
          <Marker
            position={{ lat: 34.39747331953632, lng: 133.08585302883543 }}
          />
        )}

        {/* グーデビル */}
        {(selectSpot === "0" || selectSpot === "13") && (
          <Marker
            position={{ lat: 34.398825147368896, lng: 133.083691 }}
          />
        )}

        {/* ファミマ前 */}
        {(selectSpot === "0" || selectSpot === "9") && (
          <Marker
            position={{ lat: 34.40093244277831, lng: 133.086033, }}
          />
        )}

        {/* 小学校前 */}
        {(selectSpot === "0" || selectSpot === "8") && (
          <Marker
            position={{ lat: 34.40194000000001, lng: 133.0831055423291, }}
          />
        )}


        {/* ペアシティ南側 */}
        {(selectSpot === "0" || selectSpot === "12") && (
          <Marker
            position={{ lat: 34.399071147394935, lng: 133.08153354232905, }}
          />
        )}

        {/* 駅前TP */}
        {(selectSpot === "0" || selectSpot === "17") && (
          <Marker
            position={{ lat: 34.400375564486076, lng: 133.08271383697974, }}
          />
        )}

        {/* 港TP */}
        {(selectSpot === "0" || selectSpot === "18") && (
          <Marker
            position={{ lat: 34.39826977569708, lng: 133.08146398641685, }}
          />
        )}


      </>

    )


  }


  return (
    <div>
      <div className='Calendar_block'>
        <div className='cal_form'>
          <input id="cal_day1" type="date" name="example1" defaultValue={targetDay}></input>
          <input id="cal_time1" type="time" name="example1" defaultValue={targetTime}></input>
          <p>〜</p>
          <input id="cal_day2" type="date" name="example1" defaultValue={targetDay2}></input>
          <input id="cal_time2" type="time" name="example1" defaultValue={targetTime2}></input>
          <button className='btn' id='setting' onClick={now_data}>設定</button>
          <button className='btn' id='setting2' onClick={now_csv}>
            <IconContext.Provider value={{ color: '#FFF', size: '40px', className: 'shdow' }}>
              <BiCloudDownload />
            </IconContext.Provider>
          </button>
        </div>

      </div>
      <div className='select_box'>
        <form id='login_set'>
          <select className='timeline_name2' name="timeline_name" onChange={spotChg}>

            <option value="0">全て</option>
            <option value="1">本町山脇邸前</option>
            <option value="2">本町中央</option>
            <option value="3">帝人通り</option>
            <option value="4">キオラスクエア</option>
            <option value="5">駅前商店街通り</option>
            <option value="6">三原港港湾ビル前</option>
            <option value="7">三原港物揚げ場</option>
            <option value="8">三原小学校バス停前</option>
            <option value="9">ファミリーマート前道路</option>
            <option value="10">旧勝村建材前道路</option>
            <option value="11">花本時計店交差点</option>
            <option value="12">ペアシティ南側</option>
            <option value="13">グーデビル前道路</option>
            <option value="14">エスポ前交差点</option>
            <option value="15">リージョン前道路</option>
            <option value="16">フジグラン前道路</option>
            <option value="17">駅前タッチパネル</option>
            <option value="18">港前タッチパネル</option>




          </select>
        </form>
      </div>
      <div className='dumy_box'>

        <LoadScript
          googleMapsApiKey="AIzaSyDyn-GCfgexZ61pUQ9-64TPWT5dkugImgs"
          libraries={["visualization"]}
        >
          <GoogleMap id="map"
            mapContainerStyle={containerStyle}
            center={center}
            zoom={16}
            options={options}
            onLoad={onLoad}
            onUnmount={onUnmount}

          >

            {setPoint()}

            {/* マーカーの位置を動的に変更 */}
            <Marker
              position={{ lat: latA, lng: lngA }}
              icon={customIcon}
            />





          </GoogleMap>
        </LoadScript>

      </div>


      <div className="totalBox">
        <div className='total'>
          <p>{genderStr}</p>

        </div>
        <div className='total2'>
          <p>{ageStr}</p>


        </div>
      </div>

      <div className="bar">
        <Bar_graph className="br_g" title={"時間帯別 (人/時間)"} />
      </div>

      <div className='row'>
        <div className="en"><En_graph title={"男女比率"} labels={labelsA} data={dataA} color={colorA} /></div>
        <div className="en"><En_graph2 title={"年齢比率"} labels={labelsB} color={colorB} /></div>
      </div>


      <div className='row' id='row'>
        <h1>レポート</h1>
        <div className='report' >

          <p style={paragraphStyle}>{topics}</p>



        </div>


        <div className='cal_form'>
          <input id="yobo"
            type="text" // 入力タイプをテキストに設定
            value={inputText} // inputタグの値をstateから取得
            onChange={handleInputChange} // 値が変更されたときにハンドラを呼び出す
            placeholder="要望を追加" // プレースホルダーテキスト
          />


          <button className='btn' id='setting2' onClick={now_data}>
            <IconContext.Provider value={{ color: '#FFF', size: '40px', className: 'shdow' }}>
              <BiCloudDownload />
            </IconContext.Provider>
          </button>

        </div>


      </div >

      {loading && <div className='loading'><img src={loading_img} alt="" /></div>}
    </div >






  )
}

export default Main