import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import faker from 'faker';

const LineChart = () => {
    const [data, setData] = useState({
        labels: [],
        datasets: []
    });

    useEffect(() => {
        const hours = ['9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00'];
        const generateData = () => hours.map(() => faker.datatype.number({ min: 0, max: 100 }));

        setData({
            labels: hours,
            datasets: [
                {
                    label: '通行者',
                    data: generateData(),
                    borderColor: 'rgba(54, 162, 235, 1)',
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                },
                {
                    label: '滞留者',
                    data: generateData(),
                    borderColor: 'rgba(255, 99, 132, 1)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
            ]
        });
    }, []);

    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: '時間'
                }
            },
            y: {
                title: {
                    display: true,
                    text: '人数'
                }
            }
        }
    };

    return <Line data={data} options={options} />;
};

export default LineChart;
