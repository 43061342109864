import React, { useState, useEffect, useReducer, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";
import { withCookies } from "react-cookie";
import axios from "axios";
import Main from './Main';
import Menu from './Menu';
import { IconContext } from 'react-icons' //IconContextをインポート
import { AiFillLock } from "react-icons/ai";
import { ImUsers } from "react-icons/im";
import { TiArrowForward } from "react-icons/ti";
import { TiTimes } from "react-icons/ti";

const User = () => {

    const { token } = useContext(ApiContext);
    const [userList, setUserList] = useState([]);
    const [user, setUser] = useState([]);
    const [userID, setUserID] = useState(0);
    useEffect(() => {
        getUser();

    }, [])


    const handleSubmit = async (event) => {
        event.preventDefault(); // デフォルトのフォーム送信を防止

        if (userID === 0) {


            try {
                // FormDataオブジェクトを使用してフォームデータを取得
                const uploadData = new FormData();
                uploadData.append("author", 5);
                uploadData.append("username", event.target.username.value);
                uploadData.append("password", event.target.password.value);
                uploadData.append("kengen", event.target.timeline_name.value);

                const res = await axios.post('https://tictac-clip.com/api/userinfo/', uploadData, {
                    headers: {
                        Authorization: `Token ${token}`,
                        'Content-Type': 'application/json',

                    },
                });
                alert(event.target.username.value + 'を登録しました')
                getUser();
                event.target.username.value = '';
                event.target.password.value = '';
                event.target.timeline_name.value = '管理者';
            } catch (error) {
                // ↓ エラー時のレスポンスデータ
                const errorData = error.response.data;
                console.log(error);
            }
        } else {
            const uploadData = new FormData();
            uploadData.append("author", 5);
            uploadData.append("username", event.target.username.value);
            uploadData.append("password", event.target.password.value);
            uploadData.append("kengen", event.target.timeline_name.value);





            const res2 = await axios.patch('https://tictac-clip.com/api/userinfo/' + userID + '/', uploadData, {
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',

                },
            });
            alert('編集しました')
            getUser();
            event.target.username.value = '';
            event.target.password.value = '';
            event.target.timeline_name.value = '管理者';
            setUserID(0);




        }



    };




    const getUser = async () => {
        const res = await axios.get(
            `https://tictac-clip.com/api/userinfo/`,

            {
                headers: { "Content-Type": "application/json" },
            }
        );
        setUserList(res.data);

    }

    const edit = async (id) => {

        //`https://tictac-clip.com/api/userinfo/`+id,
        //ユーザー情報を取得
        const res = await axios.get(
            `https://tictac-clip.com/api/userinfo/` + id + "/",
            {
                headers: { "Content-Type": "application/json" },
            }
        );
        console.log(`https://tictac-clip.com/api/userinfo/` + id + "/")
        //<input id="input" className="userID" name="username" type="text" placeholder="ユーザーID" />
        //class = userIDに usernameをセット
        //class = userPASSに passwordをセット
        //class = timeline_nameに kengenをセット
        const userID = document.querySelector('.userID');
        userID.value = res.data.username;
        const userPASS = document.querySelector('.userPASS');
        userPASS.value = res.data.password;
        const timeline_name = document.querySelector('.timeline_name');
        timeline_name.value = res.data.kengen;

        setUserID(id);



        //ユーザー情報をフォームにセット
        //フォームの内容を変更
        //フォームの内容を送信



    };

    const del = (id) => {
        console.log("https://tictac-clip.com/api/userinfo/" + id + "/");
        if (window.confirm('削除しますか？')) {
            try {
                axios.delete("https://tictac-clip.com/api/userinfo/" + id + "/", {
                    headers: {
                        Authorization: `Token ${token}`,

                    }
                });
                alert('削除しました');
                getUser();
            } catch (error) {
                console.log(error);
            }

        }

        setUserID(0);
    };


    return (
        <div>
            <div className='user_block'>
                <div className='block' id='login_box'>
                    <form id='login_set' onSubmit={handleSubmit}>

                        <input id="input" className="userID" name="username" type="text" placeholder="ユーザーID" />

                        <input id="input" name="password" className="userPASS" type="password" placeholder="パスワード" />
                        <select className='timeline_name' name="timeline_name"  >

                            <option key={0} value="管理者">
                                管理者
                            </option>
                            <option key={0} value="閲覧者">
                                閲覧者
                            </option>

                            <option key={0} value="イベント用">
                                イベント用
                            </option>


                        </select>

                        <button className="btn" type="submit">
                            <div className='login_set'>

                                <p id='login'>登録</p>
                            </div>
                        </button>
                    </form>

                </div>

            </div>
            <p className="userTable">ユーザー一覧</p>
            <table className="table_main">
                <tr>
                    <th className="no">No.</th>
                    <th>ユーザー権限</th>
                    <th>ユーザー名</th>
                    <th>編集</th>
                    <th>削除</th>
                </tr>
                {userList.map((user, index) => (
                    <tr key={user.id}>
                        <td>{index + 1}</td>
                        <td>{user.kengen}</td>
                        <td>{user.username}</td>
                        <td>
                            <div className='btn' id='btn_mini' onClick={() => edit(user.id)}>
                                <IconContext.Provider value={{ color: '#FFF', size: '28px' }}>
                                    <TiArrowForward className='shdowIcon2' />
                                </IconContext.Provider>
                            </div>
                        </td>
                        <td>
                            <div className='btn' id='btn_mini' onClick={() => del(user.id)}>
                                <IconContext.Provider value={{ color: '#FFF', size: '28px' }}>
                                    <TiTimes className='shdowIcon2' />
                                </IconContext.Provider>
                            </div>
                        </td>
                    </tr>
                ))}



            </table>

        </div>
    )
}

export default User