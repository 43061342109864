import React, { useState, useEffect, useReducer, useContext } from 'react';
import { ApiContext } from "../context/ApiContext";
import { IconContext } from 'react-icons' //IconContextをインポート
import { ImUsers } from "react-icons/im";
import { ImStack } from "react-icons/im";
import { ImBubbles2 } from "react-icons/im";
import { ImCogs } from "react-icons/im";
import { BsCameraReelsFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";

const TopBtn = (props) => {

    const { btnFlg, setBtnFlg, setContentName, eventStart, setEventStart, eventEnd, setEventEnd, } = useContext(ApiContext);
    const openMenu = (name) => {
        setEventStart('')
        setBtnFlg(false);
        setContentName(name)
    }

    const hiroba = () => {
        if (props.kengen == '管理者' || props.kengen == '閲覧者')
            return (
                <div className='btn_set'>
                    <div className='btn' id="big_btn" onClick={() => openMenu('広場評価')}>
                        <IconContext.Provider value={{ color: '#FFF', size: '60px' }}>
                            <ImBubbles2 className='shdowIcon' />
                        </IconContext.Provider>
                        <p>広場評価</p>
                    </div>

                </div>
            )
    }

    const camera = () => {
        if (props.kengen == '管理者')
            return (

                <div className='btn_set'>
                    <div className='btn' id="big_btn" onClick={() => openMenu('移動式カメラ設定')}>
                        <IconContext.Provider value={{ color: '#FFF', size: '60px' }}>
                            <ImCogs className='shdowIcon' />
                        </IconContext.Provider>
                        <p>移動式カメラ設定</p>
                    </div>

                </div>
            )
    }


    const user = () => {
        if (props.kengen == '管理者')
            return (

                <div className='btn_set'>
                    <div className='btn' id="big_btn" onClick={() => openMenu('ユーザー管理')}>
                        <IconContext.Provider value={{ color: '#FFF', size: '60px' }}>
                            <ImUsers className='shdowIcon' />
                        </IconContext.Provider>
                        <p>ユーザー管理</p>
                    </div>

                </div>
            )
    }

    const event = () => {
        if (props.kengen == '管理者' || props.kengen == '閲覧者')
            return (
                <div className='btn_set'>
                    <div className='btn' id="big_btn" onClick={() => openMenu('イベント設定')}>
                        <IconContext.Provider value={{ color: '#FFF', size: '60px' }}>
                            <ImCogs className='shdowIcon' />
                        </IconContext.Provider>
                        <p>イベント設定</p>
                    </div>
                </div>
            )
    }

    const preview = () => {
        if (props.kengen == '管理者')
            return (

                <div className='btn_set'>
                    <div className='btn' id="big_btn" onClick={() => openMenu('プレビュー')}>
                        <IconContext.Provider value={{ color: '#FFF', size: '60px' }}>
                            <BsCameraReelsFill className='shdowIcon' />
                        </IconContext.Provider>
                        <p>プレビュー</p>
                    </div>

                </div>
            )
    }
    const profile = () => {
        if (props.kengen == '管理者')
            return (

                <div className='btn_set'>
                    <div className='btn' id="big_btn" onClick={() => openMenu('プロフィール')}>
                        <IconContext.Provider value={{ color: '#FFF', size: '60px' }}>
                            <FaEdit className='shdowIcon' />
                        </IconContext.Provider>
                        <p>街のプロフィール</p>
                    </div>

                </div>
            )
    }


    const all = () => {
        if (props.kengen == '管理者' || props.kengen == '閲覧者' || props.kengen == 'イベント用')
            return (
                <div className='btn_set'>
                    <div className='btn' id="big_btn" onClick={() => openMenu('全体マップ')}>
                        <IconContext.Provider value={{ color: '#FFF', size: '60px' }}>
                            <ImStack className='shdowIcon' />
                        </IconContext.Provider>
                        <p>全体マップ</p>
                    </div>

                </div>
            )
    }

    return (
        <div className='main'>
            <div className='box_top'>

                {all()}
                {hiroba()}
                {camera()}
                {user()}
                {event()}
                {preview()}

                {profile()}

            </div>

        </div>
    )
}

export default TopBtn