import React, { useState, useEffect } from 'react';
import { Bubble } from 'react-chartjs-2';
import 'chart.js/auto';
import faker from 'faker';

const BubbleChart = () => {
    const [data, setData] = useState({
        datasets: []
    });

    useEffect(() => {
        const generateData = (gender) => Array.from({ length: 25 }, () => ({
            x: faker.datatype.number({ min: 100, max: 1000 }), // 人数
            y: faker.datatype.number({ min: 0, max: 100 }),   // 年齢層
            r: faker.datatype.number({ min: 5, max: 50 })    // バブルのサイズ
        }));

        setData({
            datasets: [
                {
                    label: '男性',
                    data: generateData('male'),
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                },
                {
                    label: '女性',
                    data: generateData('female'),
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
            ]
        });
    }, []);

    const options = {
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                title: {
                    display: true,
                    text: '人数'
                }
            },
            y: {
                title: {
                    display: true,
                    text: '年齢層'
                }
            }
        }
    };

    return <Bubble data={data} options={options} />;
};

export default BubbleChart;
