import React, { useState, useEffect, useReducer, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);






export const En_graph2 = (props) => {
  const { faceList, setFaceList, selectSpot, setSelectSpot, dataList, setAgeStr } = useContext(ApiContext);
  const [datas, setDatas] = useState([]);
  const [countF, setCountF] = useState(0);

  const setGrah = () => {
    let count_temp = [0, 0, 0, 0, 0, 0, 0, 0];
    //70over
    let total = 0
    if (selectSpot == "0") {
      for (let i = 0; i < dataList.length; i++) {
        const age = dataList[i].age
        if (age == "0-9") {
          count_temp[0]++
        } else if (age == "10-19") {
          count_temp[1]++
        } else if (age == "20-29") {
          count_temp[2]++
        } else if (age == "30-39") {
          count_temp[3]++
        } else if (age == "40-49") {
          count_temp[4]++
        } else if (age == "50-59") {
          count_temp[5]++
        } else if (age == "60-69") {
          count_temp[6]++
        } else if (age == "70over") {
          count_temp[7]++
        }
        total++;
      }
    } else {
      for (let i = 0; i < dataList.length; i++) {
        if (dataList[i].name == selectSpot) {
          const age = dataList[i].age
          if (age == "0-9") {
            count_temp[0]++
          } else if (age == "10-19") {
            count_temp[1]++
          } else if (age == "20-29") {
            count_temp[2]++
          } else if (age == "30-39") {
            count_temp[3]++
          } else if (age == "40-49") {
            count_temp[4]++
          } else if (age == "50-59") {
            count_temp[5]++
          } else if (age == "60-69") {
            count_temp[6]++
          } else if (age == "70over") {
            count_temp[7]++
          }
          total++;
        }
      }
    }


    setDatas([count_temp[0] / total * 100, count_temp[1] / total * 100, count_temp[2] / total * 100, count_temp[3] / total * 100, count_temp[4] / total * 100, count_temp[5] / total * 100, count_temp[6] / total * 100, count_temp[7] / total * 100])

    setAgeStr("〜10歳　" + count_temp[0] + "人(" + (count_temp[0] / total * 100).toFixed(2) + "%)　10〜19歳　" + count_temp[1] + "人(" + (count_temp[1] / total * 100).toFixed(2) + "%)　20〜29歳　" + count_temp[2] + "人(" + (count_temp[2] / total * 100).toFixed(2) + "%)　30〜39歳　" + count_temp[3] + "人(" + (count_temp[3] / total * 100).toFixed(2) + "%)　40〜49歳　" + count_temp[4] + "人(" + (count_temp[4] / total * 100).toFixed(2) + "%)　50〜59歳　" + count_temp[5] + "人(" + (count_temp[5] / total * 100).toFixed(2) + "%)　60〜69歳　" + count_temp[6] + "人(" + (count_temp[6] / total * 100).toFixed(2) + "%)　70歳〜　" + count_temp[7] + "人(" + (count_temp[7] / total * 100).toFixed(2) + "%)")

  }
  useEffect(() => {

    setGrah()

  }, [dataList, selectSpot]);


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: true,
        text: props.title
      }
    }
  };

  const data = {
    labels: props.labels,
    datasets: [
      {

        data: datas,
        backgroundColor: props.color,
        borderWidth: 1,
      },
    ],
  };
  return (
    <div>
      <Pie options={options} data={data} />

    </div>

  )
}

export default En_graph2