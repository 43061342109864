import React, { useState, useEffect, useReducer, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);






export const En_graph = (props) => {
  const { dataList, faceList, setFaceList, selectSpot, setSelectSpot, setGenderStr } = useContext(ApiContext);
  const [datas, setDatas] = useState([]);
  const [countF, setCountF] = useState(0);

  const setGrah = () => {
    let countM_temp = 0;
    let countF_temp = 0;
    if (selectSpot == "0") {
      for (let i = 0; i < dataList.length; i++) {

        if (dataList[i].Gender == "m") {
          countM_temp++;
        } else {
          countF_temp++;
        }
      }
    } else {
      for (let i = 0; i < dataList.length; i++) {
        if (dataList[i].name == selectSpot) {
          if (dataList[i].Gender == "m") {
            countM_temp++;
          } else {
            countF_temp++;
          }
        }
      }

    }
    const total = countM_temp + countF_temp
    setDatas([countM_temp / total * 100, countF_temp / total * 100])
    setGenderStr("通行人数:" + total + "人" + "　男性:" + countM_temp + "人(" + (countM_temp / total * 100).toFixed(2) + "%)　女性:" + countF_temp + "人(" + (countF_temp / total * 100).toFixed(2) + "%)")
    /*
    for (let i = 0; i < faceList.length; i++){
      const face = faceList[i]
      if(face.time>=3 && face.time<=10){
        if(selectSpot == 0){
          if(face.gender == 'M'){
            countM_temp ++;
          }else{
            countF_temp ++;
          }
        }else{
          if(face.gender == 'M' && face.spot == selectSpot){
            countM_temp ++;
          }else if(face.spot == selectSpot){
            countF_temp ++;

          }
        }

      }
    }
    const total = countM_temp+countF_temp
    setDatas([countM_temp/total*100,countF_temp/total*100])

    */

  }
  useEffect(() => {

    setGrah()

  }, [dataList, selectSpot]);


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: true,
        text: props.title
      }
    }
  };

  const data = {
    labels: props.labels,
    datasets: [
      {

        data: datas,
        backgroundColor: props.color,
        borderWidth: 1,
      },
    ],
  };
  return (
    <div>
      <Pie options={options} data={data} />

    </div>

  )
}

export default En_graph