import React, { useState, useEffect, useReducer, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);










export const Bar_graph = (props) => {
  const { dataList, faceList, setFaceList, selectSpot, setSelectSpot } = useContext(ApiContext);
  const spotNameList = ["全て", "本町山脇邸前", "本町中央", "帝人通り", "キオラスクエア", "駅前商店街通り", "三原港港湾ビル前", "三原港物揚げ場", "三原小学校バス停前", "ファミリーマート前道路", "旧勝村建材前道路", "花本時計店交差点", "ペアシティ南側", "グーデビル前道路", "エスポ前交差点", "リージョン前道路", "フジグラン前道路", "駅前タッチパネル", "港前タッチパネル"]
  let countM_temp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let countF_temp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const [countM, setCountM] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [countF, setCountF] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const setGrah = () => {
    if (selectSpot == "0") {
      for (let i = 0; i < dataList.length; i++) {
        const data = dataList[i]
        let timeNum = data.post_time.split('T')[1]
        timeNum = timeNum.split(':')[0]
        timeNum = parseInt(timeNum)
        //
        const gender = data.Gender
        if (gender == 'm') {
          const count_m = countM_temp[timeNum]
          countM_temp[timeNum] = count_m + 1;
        } else {
          const count_f = countF_temp[timeNum]
          countF_temp[timeNum] = count_f + 1;
        }
      }
    } else {

      for (let i = 0; i < dataList.length; i++) {
        const data = dataList[i]
        let timeNum = data.post_time.split('T')[1]
        timeNum = timeNum.split(':')[0]
        timeNum = parseInt(timeNum)
        //
        const gender = data.Gender
        if (gender == 'm' && data.name == selectSpot) {
          const count_m = countM_temp[timeNum]
          countM_temp[timeNum] = count_m + 1;
        } else if (gender == 'w' && data.name == selectSpot) {
          const count_f = countF_temp[timeNum]
          countF_temp[timeNum] = count_f + 1;
        }
      }

    }
    setCountM(countM_temp)
    setCountF(countF_temp)




  }
  useEffect(() => {

    setGrah()

  }, [dataList, selectSpot]);


  const labels = ["0時", "1時", "2時", "3時", "4時", "5時", "6時", "7時", "8時", "9時", "10時", "11時", "12時", "13時", "14時", "15時", "16時", "17時", "18時", "19時", "20時", "21時", "22時", "23時"];


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: true,
        text: props.title
      }
    }
  };

  const data = {
    labels, // x軸のラベルの配列
    datasets: [
      {
        label: "男性", // 凡例
        data: countM,        // データの配列(labelsと要素数同じ)
        backgroundColor: "rgba(53, 162, 235, 0.5)" // グラフの棒の色
      },
      {
        label: "女性",
        data: countF,
        backgroundColor: "rgba(255, 99, 132, 0.5)"
      }
    ]
  };

  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  )
}

export default Bar_graph