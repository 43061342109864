import React, { useState, useEffect, useReducer, useContext } from 'react';
import { ApiContext } from "../context/ApiContext";
import axios from "axios";




const Event = () => {
    const { btnFlg, setBtnFlg, contentName, setContentName, eventStart, setEventStart, eventEnd, setEventEnd, adress, setAdress } = useContext(ApiContext);
    const [pastList, setPastList] = useState([]);
    const [futureList, setFutureList] = useState([]);
    const openMenu = (name) => {
        setBtnFlg(false);
        setContentName(name)
    }

    const eventLink = () => {
        console.log("click")
    }

    const getEvent = async () => {
        let tempPastList = [];
        let tempFutureList = [];

        const res = await axios.get('https://tictac-clip.com/api/eventinfo/')

        for (let i = 0; i < res.data.length; i++) {
            const day = convertDateRange(res.data[i].dateFrom);
            const from = day.split('-')[0];
            const to = day.split('-')[1];

            const kaisaiFlg = kaisai(from)
            if (kaisaiFlg) {
                //console.log('未来: ' + res.data[i].name + ' ' + from + ' ' + to)
                tempFutureList.push(res.data[i])

            } else {
                //console.log('終了: ' + res.data[i].name + ' ' + from + ' ' + to)

                tempPastList.push(res.data[i])
            }
            //tempPastList逆にする


            //const targetDay = new Date(from);
        }
        tempPastList.reverse();
        tempFutureList.reverse();

        console.log(tempPastList)

        setPastList(tempPastList);
        setFutureList(tempFutureList);
    }

    const kaisai = (dateString) => {

        const today = new Date();

        const regex = /(\d{4})年(\d{2})月(\d{2})日\(.\)/;
        const match = dateString.match(regex);


        // 年、月（0から始まるため-1する）、日を用いてDateオブジェクトを作成
        const year = parseInt(match[1], 10);
        const month = parseInt(match[2], 10) - 1; // JavaScriptの月は0から始まる
        const day = parseInt(match[3], 10);

        const date = new Date(year, month, day);

        // 2つの日付の比較
        if (today.getTime() === date.getTime()) {
            //console.log('同じ日付');
            return false;
        } else if (today.getTime() < date.getTime()) {
            //console.log('未来の日付');
            return true;
        } else {
            //console.log('過去の日付');
            return false;
        }


    }

    const convertDateRange = (text) => {
        const regex = /(\d{4}年\d{2}月\d{2}日\([日月火水木金土]\))[\・~](\d{4}年\d{2}月\d{2}日\([日月火水木金土]\))/g;
        //先頭に・がある場合は削除
        text = text.replace(/^[\・~]/, '');
        return text.replace(regex, '$1-$2');
    }
    const covertSpot = (text) => {


        const convertedString = text.replace(/\n/g, '<br>');
        return <td dangerouslySetInnerHTML={{ __html: convertedString }}></td>;

    }
    const handleRowClick = (pastData) => {

        const adress = pastData.adress.split('\n')[0];
        let day = convertDateRange(pastData.dateFrom);
        //dayの頭に"・"が含まれている場合は削除
        day = day.replace(/^・/, '');

        console.log(day)
        //dayに"-"が含まれている場合は、"・"に変換
        const dayStr = day.replace(/-/g, '・');
        const dayStrList = dayStr.split('・');
        //dayStrListをソート
        dayStrList.sort();

        //dayStrListの最初を取得
        const dayStrFirst = dayStrList[0];

        //dayStrListの最後を取得
        const dayStrLast = dayStrList[dayStrList.length - 1];

        const dayStrFirstF = formatDate(dayStrFirst)
        const dayStrLastF = formatDate(dayStrLast)

        console.log(dayStrFirstF, dayStrLastF)

        setEventStart(dayStrFirstF);
        setEventEnd(dayStrLastF);


        setAdress(adress);
        console.log("クリックされた行のデータ:", adress);
        setContentName('全体マップ')
        // ここに必要な処理を追加。例えば、別の画面に遷移する、詳細情報を表示するなど
    };

    useEffect(() => {

        getEvent();

    }, []);
    function formatDate(input) {
        // 正規表現を使用して年、月、日を抽出
        const regex = /(\d{4})年(\d{2})月(\d{2})日/;
        const matches = input.match(regex);

        if (matches) {
            // matches[1]は年、matches[2]は月、matches[3]は日
            const year = matches[1];
            const month = matches[2];
            const day = matches[3];

            // フォーマット `yyyy-mm-dd` で返す
            return `${year}-${month}-${day}`;
        } else {
            return null; // マッチしない場合はnullを返す
        }
    }


    return (
        <div>
            <p className='eventTitle'>開催中・開催済</p>
            <div className="eventBox">
                <table className="table_event">
                    <tr>

                        <th>名称</th>
                        <th id="ctg">カテゴリー</th>
                        <th id="day">日時</th>
                        <th id="spot">場所</th>

                    </tr>


                    {pastList.map((past) => (
                        <tr className="item" onClick={() => handleRowClick(past)}>
                            <td>{past.name}</td>
                            <td>{past.type}</td>
                            <td>{convertDateRange(past.dateFrom)}</td>
                            {covertSpot(past.adress)}

                        </tr>
                    ))}




                </table>

            </div>

            <p className='eventTitle'>開催予定</p>
            <div className="eventBox">
                <table className="table_event">
                    <tr>

                        <th>名称</th>
                        <th id="ctg">カテゴリー</th>
                        <th id="day">日時</th>
                        <th id="spot">場所</th>

                    </tr>


                    {futureList.map((future) => (
                        <tr >
                            <td>{future.name}</td>
                            <td>{future.type}</td>
                            <td>{convertDateRange(future.dateFrom)}</td>
                            {covertSpot(future.adress)}

                        </tr>
                    ))}




                </table>

            </div>

        </div >
    )
}

export default Event