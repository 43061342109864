import React, { useState, useEffect, useReducer, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";
import { withCookies } from "react-cookie";
import axios from "axios";
import Main from './Main';
import Menu from './Menu';
import { IconContext } from 'react-icons' //IconContextをインポート
import { AiFillLock } from "react-icons/ai";


import ApiContextProvider from "../context/ApiContext";
import { tr } from 'faker/lib/locales';




export const Login = (props) => {

  const [loginFlg, setLoginFlg] = useState(true);
  const [kengen, setKengen] = useState('');
  const login_press = async (event) => {

    //className="name"を取得
    const name = document.getElementsByClassName('name')[0].value;
    const password = document.getElementsByClassName('password')[0].value;

    //a6f363db5aba430b060a4fba808e4eefbbe1d51b

    const res = await axios.get(
      `https://tictac-clip.com/api/userinfo/`,

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log(res.data);

    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].username === name && res.data[i].password === password) {

        setKengen(res.data[i].kengen);
        setLoginFlg(false);
      }
    }


    /*
    event.preventDefault();
    try {
      dispatch({ type: START_FETCH });
      setLoginName(state.credentialsLog.username);

      const res = await axios.post(
        `https://tictac-clip.com/api/rest-auth/login/`,
        state.credentialsLog,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      props.cookies.set("jwt-token", res.data.key);
      res.data.key
        ? (mainView())
        : (console.log('err'));
      dispatch({ type: FETCH_SUCCESS });
    } catch {
      console.log('err!');
      dispatch({ type: ERROR_CATCHED });
    }
    {!state.isVisible && <Menu user={loginName} />}
    <div className={state.isVisible ? 'login_container' : 'invisible'}>
    */

  }


  return (
    <ApiContextProvider>
      {!loginFlg && <Menu kengen={kengen} />}
      <div className={loginFlg ? 'login_container' : 'invisible'}>

        <div className='block' id='login_box'>

          <h1>三原市AIカメラ</h1>
          <p>ver.2.0.1</p>



          <input id="input" className="name" name="username" type="text" placeholder="ユーザーID" />

          <input id="input" className="password" name="password" type="password" placeholder="パスワード" />

          <button className="btn" onClick={login_press}>
            <div className='login_set'>
              <IconContext.Provider value={{ color: '#FFF', size: '20px' }}>
                <AiFillLock className='shdowLock' />
              </IconContext.Provider>
              <p id='login'>Login</p>
            </div>
          </button>

        </div>
      </div>
    </ApiContextProvider >


  )
}



export default withCookies(Login);






