import React, { useState, useEffect, useReducer, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);








export const Line_graph2 = (props) => {
  const { faceList, setFaceList, selectSpot, setSelectSpot } = useContext(ApiContext);
  const [labels, setLabel] = useState([]);
  const [count, setCount] = useState([]);
  let count_temp = [];
  let label_temp = [];
  for (let i = 0; i < 101; i++) {
    //count_temp.pus

  }
  const setGrah = () => {
    count_temp = [];
    for (let i = 0; i < faceList.length; i++) {
      const face = faceList[i]

      if (face.time <= 10) {
        if (selectSpot == 0) {
          let time = Math.round(face.time)
          let tempNum = 0;
          if (count_temp[time] == undefined) {
            tempNum = 0
          } else {
            tempNum = count_temp[time]
          }
          count_temp[time] = tempNum + 1;
        } else if (face.spot == selectSpot) {

          let time = Math.round(face.time)
          let tempNum = 0;

          if (count_temp[time] == undefined) {
            tempNum = 0
          } else {
            tempNum = count_temp[time]
          }
          count_temp[time] = tempNum + 1;
        }

      }
    }

    for (let i = 0; i < count_temp.length; i++) {
      label_temp.push(String(i + 2))
    }
    console.log(label_temp)
    setCount(count_temp)
    setLabel(label_temp)

  }
  useEffect(() => {

    setGrah()

  }, [faceList]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: true,
        text: "滞在時間(人/時間)"
      }
    }
  };

  //const labels = ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89'];

  const data = {
    labels,
    datasets: [
      {
        label: '秒',
        data: count,

        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 1)',
      },


    ],
  };
  return (
    <div>
      <Line options={options} data={data} />;

    </div>
  )
}

export default Line_graph2