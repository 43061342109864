import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import { CookiesProvider } from 'react-cookie';
import './scss/parts.scss'
import './scss/common.scss'
import  Login from './components/Login';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Login />);
