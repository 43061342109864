import React, { useState, useEffect, useReducer, useContext } from 'react';
import { ApiContext } from "../context/ApiContext";
import { IconContext } from 'react-icons' //IconContextをインポート

import User from './User';
import Event from './Event';
import Camera from './Camera';
import Hiroba from './Hiroba';
import Profile from './Profile';
import Preview from './Preview';
import Map from './Map';
import Main from './Main';
import { FaSearch } from "react-icons/fa";
import { FaWindowClose } from "react-icons/fa";

const Setting = () => {
    const { btnFlg, setBtnFlg, contentName } = useContext(ApiContext);
    const [serchWord, setSerchWord] = useState('');
    const [serchFlg, setSerchFlg] = useState(false);
    const topPage = () => {
        setBtnFlg(true)
    }
    const serchFunc = () => {

    }
    return (
        <div>
            <div className='setting_box'>
                <p className="top_btn" onClick={topPage}>トップページ</p>
                <p>　＞　</p>
                <p>{contentName}</p>



            </div>

            <div className='contents_box'>

                {
                    (() => {
                        if (contentName == 'ユーザー管理') {
                            return (<User />)
                        } else if (contentName == '全体マップ') {
                            return (<Main />)
                        } else if (contentName == 'イベント設定') {
                            return (<Event />)
                        } else if (contentName == '移動式カメラ設定') {
                            return (<Camera />)
                        } else if (contentName == '広場評価') {
                            return (<Hiroba />)
                        } else if (contentName == 'プレビュー') {
                            return (<Preview />)

                        } else if (contentName == 'プロフィール') {
                            return (<Profile />)
                        }
                    })()
                }


            </div>
        </div>

    )
}

export default Setting